import React, { useEffect, useState } from 'react';
import { BsArrowUpCircle } from 'react-icons/bs'

const GoToTop = () =>
{
    const [ isVisible, setIsVisible ] = useState(false)
    const goToBtn = () =>
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    const listenToScroll = () =>
    {
        let hidden = 250
        const windScroll = document.body.scrollTop || document.documentElement.scrollTop
        if (windScroll > hidden)
        {
            setIsVisible(true)
        } else
        {
            setIsVisible(false)
        }
    }

    useEffect(() =>
    {
        window.addEventListener('scroll', listenToScroll)
    }, [])

    return (
        <div className='flex justify-end  ' >
            {isVisible && <button onClick={goToBtn} className='mr-12 flex items-center bg-white rounded-md p-2 font-bold text-red-700'><BsArrowUpCircle className='mt-[1px] ' /> GoToTop</button>}
        </div>
    );
};

export default GoToTop;