import Root from "../Layout/Root";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
import Home from "../Pages/LandingPage/Home/Home";
import WorkDetails from "../Pages/Work/WorkDetails";
import ErrorPage from "../components/ErrorPage/ErrorPage";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
    {

        path: '/', element: <Root />, errorElement: <ErrorPage />, children: [
            { path: '/', element: <Home /> },
            {path:'/detail/:id',element:<WorkDetails/>},
            { path: '/contact', element: <Contact /> },
            { path: '/about', element: <About /> }

        ]
    },
    {
        path: '*', errorElement: 'not found'
    }
])

export default router
