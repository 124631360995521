import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Shared/Footer/Footer'
import Navbar from '../Shared/Navbar/Navbar'
import GoToTop from '../components/GoToTop/GoToTop'

const Root = () =>
{
  

    return (
        <section className='bg-black relative '>
            <div className='sticky  top-[85%]  z-50 '>
                <GoToTop />
            </div>
            <Navbar />
            <div >
                <Outlet />
            </div>

            <Footer />
        </section>
    )
}

export default Root