import React from 'react'
import Banner from '../Banner/Banner'
import Courses from '../Courses/Courses'
import Department from '../Department/Department'
import Work from '../../Work/Work'

const Home = () => {
    return (
        <section>
            <Banner />
            {/* <Department />
            <Courses/>  */}
            <Work />
            
        </section>
    )
}

export default Home