import React, { useEffect, useState } from 'react'
import img1 from '../../Assests/Work-images/Distric News IDENT_For Rtv.png'
import img2 from '../../Assests/Work-images/Rtv_2015_Rebranding _Rtv.png'
import img3 from '../../Assests/Work-images/21 Feb 2016 Branding For Rtv.png'
import img4 from '../../Assests/Work-images/Freelancer Client.png'
import img5 from '../../Assests/Work-images/16 December Branding, Rtv.png'
import img6 from '../../Assests/Work-images/Special News IDENT_Rtv.png'
import img7 from '../../Assests/Work-images/Program Opening Title_IDENT.png'
import img8 from '../../Assests/Work-images/Epic Countdown (videohive.net).jpg'
import img9 from '../../Assests/Work-images/Valentine-Wedding Slideshow (videohive.net).jpg'
import img10 from '../../Assests/Work-images/16 December Branding, Rtv.png'

import { Link } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'

function Work ()
{
    const [ datas, setDatas ] = useState([])
    const workDatas = [

        {
            _id: 1,
            title: 'Jela Songbad',
            picture: img1,
            url: 'https://www.behance.net/gallery/36960121/Distric-News-IDENT_For-Rtv'

        },
        {
            _id: 2,
            title: 'Rtv_2015_Rebranding _Rtv',
            picture: img2,
            url: 'https://www.behance.net/gallery/38439907/Bumper_2015_With-Watching-_Rtv'
        },
        {
            _id: 3,
            title: '21 Feb 2016 Branding For Rtv',
            picture: img3,
            url: 'https://www.behance.net/gallery/36958953/21-Feb-2016-Branding-For-Rtv'
        },
        {
            _id: 4,
            title: '3D Logo Animation Freelancer Client',
            picture: img4,
            url: 'https://www.behance.net/portfolio/editor?project_id=41912373'
        },
        {
            _id: 5,
            title: 'ReBranding For Rtv',
            picture: img5,
            url: 'https://www.behance.net/portfolio/editor?project_id=46954745'
        },
        {
            _id: 6,
            title: 'Special News IDENT_Rtv',
            picture: img6,
            url: 'https://www.behance.net/portfolio/editor?project_id=50092291'
        },
        {
            _id: 7,
            title: 'Program Opening Title_IDENT',
            picture: img7,
            url: 'https://www.behance.net/gallery/66231889/Program-Opening-Title_IDENT'
        },
        {
            _id: 8,
            title: 'Epic Countdown',
            picture: img8,
            url: 'https://videohive.net/item/epic-countdown/22434754'
        },
        {
            _id: 9,
            title: 'Valentine/ Wedding Slideshow (videohive.net)',
            picture: img9,
            url: 'https://videohive.net/item/valentine-wedding-slideshow/22681251'
        },
        {
            _id: 10,
            title: '16 December Branding, Rtv',
            picture: img10,
            url: 'https://www.behance.net/gallery/73490461/16-December-Branding-Bangladesh'
        },
    ]
    useEffect(() =>
    {
        fetch(`${ process.env.REACT_APP_API_SERVER_URL }portfolio`).then(res => res.json()).then(data =>
        {
            // console.log(data)
            setDatas(data)
        })
    }, [])


    return (
        <main className='pb-5'>
            {datas?.length < 1 ? <div>
                < Spinner />
            </div> : <section className='pt-5 grid lg:grid-cols-4 md:grid-cols-3  '>
                {
                    datas?.map(data => <Link to={`/detail/${ data?._id }`} className='relative transition-all block bg-[#345965] group sm:h-[350px] ' key={data?._id}>

                        <img className='absolute inset-0 object-cover 
                               h-full w-full transition-all group-hover:opacity-10' src={data?.picture} alt="" />

                        <div className="relative p-5">
                            <div className="mt-40">
                                <div className="transition-all transform 
                                translate-y-8 opacity-0 
                                group-hover:opacity-100 
                                group-hover:translate-y-0">
                                    <div className="p-2">
                                        <p className="text-2xl font-bold text-white">
                                            {data?.title}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>)
                }</section>
            }

        </main >
    )
}

export default Work
