import React from 'react';

const Spinner = () => {
    return (
        <div className='flex justify-center text-white items-start pt-5 w-full h-[500px] text-5xl font-bold'>
            <div className='flex items-center'>
                <h1 className='animate-pulse'>L</h1>
                    <div className="w-7 h-7 border-4 border-dashed mt-5 rounded-full animate-spin border-red-800"></div>
                <p className='animate-pulse'>ading</p>
            </div>
        </div>
    );
}

export default Spinner;
