import React, { useRef, useState } from 'react'
import emailjs from "@emailjs/browser";

function Contact ()
{
    const form = useRef();
    const [ done, setDone ] = useState(false);
    const sendEmail = (e) =>
    {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_fw1aj2r",
                "template_h866lwi",
                form.current,
                "w1lyvILBFbG3awpdD"
            )
            .then(
                (result) =>
                {
                    console.log(result.text);
                    setDone(true);
                    form.current.reset()
                    console.log(form.current);

                },
                (error) =>
                {
                    console.log(error.text);
                }
            );
    };
    return (
        <main className=' text-white my-20'>
            <section className="py-6">
                <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
                    <div className="py-6 md:py-0 md:px-6">
                        <h1 className="text-4xl font-bold">Get in touch</h1>
                        <p className="pt-2 pb-4">Fill in the form to start a conversation</p>
                        <div className="space-y-4">
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6 mr-2 sm:mr-6">
                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                                </svg>
                                <span>H#147/3/C (4th Floor) Moddhopara, Meradia, Khilgaon-Dhaka-1219</span>
                            </p>
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                </svg>
                                <span>+880-01671940351</span>
                            </p>
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                <span>iftekhairul.islam@gmail.com</span>
                            </p>
                        </div>
                    </div>
                    <form ref={form} onSubmit={sendEmail} className="flex text-white flex-col py-6 space-y-6 md:py-0 md:px-6">
                        <label className="block">
                            <span className="mb-1">Full name</span>
                            <input type="text" required id='name' name="user_name" placeholder="Enter Your Name" className="block w-full py-2 rounded-md shadow-sm focus:ring focus:ri focus:ri border text-black font-semibold pl-1 " />
                        </label>
                        <label className="block">
                            <span className="mb-1">Email address</span>
                            <input required id='email' type="email" name="user_email" placeholder="example@gmail.com" className="block w-full py-2 rounded-md shadow-sm focus:ring focus:ri focus:ri border text-black font-semibold  pl-1" data-temp-mail-org="0" />
                        </label>
                        <label className="block">
                            <span className="mb-1">Message</span>
                            <textarea required id='message' name="message" rows="3" className="block w-full rounded-md focus:ring focus:ri focus:ri border text-black font-semibold pl-1 " placeholder='Your message.....'></textarea>
                        </label>
                        <button type="submit" value="Send" className="self-center px-8 py-3 text-lg rounded focus:ring hover:ring focus:ri dark:bg-violet-900 text-gray-300 font-bold hover:ri">Submit</button>
                        <span className="text1">{done && "Thanks for Contacting us"}</span>
                    </form>

                </div>
            </section>
            <section>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230909.50116511656!2d89.58961976545787!3d25.27189883989743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fd41e4ee1618af%3A0x5c46c1ce4da21d04!2sDewanganj%20Upazila!5e0!3m2!1sen!2sbd!4v1686968447451!5m2!1sen!2sbd" className='border-0 w-full h-[500px]' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </main>
    )
}

export default Contact
