import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const WorkDetails = () =>
{
    const [ data, setData ] = useState({})
    const params = useParams()
    useEffect(() =>
    {
        fetch(`${ process.env.REACT_APP_API_SERVER_URL }portfolio/${ params?.id }`).then(res => res.json()).then(data =>
        {
            setData(data)
            // console.log(data);
        })
    }, [ params?.id ])

    const { title, discription, date, service, video, images } = data
    console.log(images);
    return (
        <main className='text-white overflow-hidden pb-6'>
            <section className='w-1/2 mx-auto pt-14 pb-10'>
                <h1 className='md:text-6xl text-5xl font-bold '>{title}</h1>

            </section>
            <section className='w-full h-full pl-2  '>
                {images?.map((img, i) => <img key={i} className='w-screen pb-2' src={img?.img} alt='' />)}
            </section>
            <section>
                {video &&
                    <iframe className={`w-screen h-screen  `} src={video} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                }


            </section>
            <section className='m-16 p-12 flex justify-center border-2'>
                <button>Buy Now</button>
            </section>
        </main>
    );
};

export default WorkDetails;