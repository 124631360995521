import React, { useState } from 'react'
import { FaPlus, FaRegPlayCircle } from 'react-icons/fa';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import banner from '../../../Assests/BANNER.jpg'
const Banner = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <section style={{ backgroundImage: `url(${banner})` }} >
            <h1 className=' text-center font-medium text-[#f9f7f7] md:pt-20 md:pb-12 md:text-6xl text-4xl '>WE ARE A CREATIVE BRANDING AGENCY.</h1>
        </section>
    )
}

export default Banner