import React from 'react'

function About ()
{
    return (
        <main>
            <div className="px-4 py-16 mx-auto sm:max-w-xl text-gray-300 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-300 uppercase rounded-full bg-teal-accent-400">
                                Brand new
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-200 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
                                <svg
                                    viewBox="0 0 52 24"
                                    fill="currentColor"
                                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                                >
                                    <defs>
                                        <pattern
                                            id="70326c9b-4a0f-429b-9c76-792941e326d5"
                                            x="0"
                                            y="0"
                                            width=".135"
                                            height=".30"
                                        >
                                            <circle cx="1" cy="1" r=".7" />
                                        </pattern>
                                    </defs>
                                    <rect
                                        fill="url(#70326c9b-4a0f-429b-9c76-792941e326d5)"
                                        width="52"
                                        height="24"
                                    />
                                </svg>
                                <span className="relative">Welcome</span>
                            </span>{' '}
                            to our website
                        </h2>
                        <p className="text-base  md:text-lg">
                            your one-stop destination for cutting-edge creative services. We are a global provider of 3D animation, motion graphics, video editing, graphic design, and web development solutions. Our team of skilled professionals is dedicated to delivering high-quality, visually stunning, and engaging content to clients worldwide.

                        </p>
                    </div>
                </div>
                <div className="max-w-screen-xl sm:mx-auto">
                    <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
                        <div className="space-y-8">
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    At our core, we are storytellers.
                                </p>
                                <p className="">
                                    We believe that every brand, product, or idea has a unique story that deserves to be told in the most captivating and memorable way possible. With our expertise in 3D animation and motion graphics, we bring these stories to life, adding depth, movement, and interactivity to captivate your audience.

                                </p>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">

                                    Video editing is another area where we excel.
                                </p>
                                <p className="">
                                    We understand the power of editing to transform raw footage into a compelling narrative. Whether it's a corporate video, promotional content, or a full-length feature film, our team has the skills and creativity to make your vision come alive on the screen.

                                </p>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">

                                    Our graphic design services encompass a wide range of visual communication solutions.
                                </p>
                                <p className="">
                                    From logo design and branding to packaging and print materials, we work closely with our clients to understand their unique requirements and create designs that make a lasting impression.

                                </p>
                                <div className='pt-5'>
                                    <p className="mb-4 text-xl font-medium">
                                        Explore our website to learn more about our services, view our portfolio,
                                    </p>
                                    <p className="">
                                        and get in touch with us to discuss how we can bring your ideas to life. We look forward to collaborating with you and creating compelling visual experiences that will elevate your brand and captivate your audience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-8">
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    In today's digital world, having a strong online presence is crucial.
                                </p>
                                <p className="">
                                    That's why we offer comprehensive web development services to help businesses establish their identity on the internet. Our team of web developers is proficient in various programming languages and platforms, ensuring that your website is not only visually appealing but also functional, user-friendly, and optimized for search engines.

                                </p>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    What sets us apart is our commitment to quality and customer satisfaction.
                                </p>
                                <p className="">
                                    We take the time to understand your specific needs and work closely with you throughout the entire creative process, from conceptualization to final delivery. Our goal is to exceed your expectations and provide you with a result that not only meets your requirements but also leaves a lasting impact on your audience.

                                </p>
                                <div>

                                </div>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    Whether you're a small business owner,
                                </p>
                                <p className="">
                                    a marketing agency, or a production company, we have the expertise and resources to handle projects of any scale or complexity. Our dedicated team is passionate about what they do and continually pushes the boundaries of creativity and innovation.


                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default About
